import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import MainSpinner from '../components/MainSpinner';
import SignaturePad from 'signature_pad';
import { Card } from '@material-tailwind/react';
import { Switch } from '@mui/material';
import fallbackImage from '../assets/img/img-profile-error.png';
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";



const StaffManagement = ({ transport }) => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState({
    card1: false,
    card2: false,
  });
  const [users, setUsers] = useState([]); // Lista de usuários

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    username: '',
    password: '',
    confirmPassword: '',
    user_role_id: '1',
    trailer_type: '',
  });
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('1');
  const [isDriver, setIsDriver] = useState(false);
  const signaturePadRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(users.length / itemsPerPage);
  const currentUsers = users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);



  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isDriver && canvasRef.current && !signaturePadRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [isDriver]);

  const toggleCard = (cardKey) => {
    setIsOpen((prev) => ({
      ...prev,
      [cardKey]: !prev[cardKey],
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updated = { ...prev, [name]: value };
      if (name === 'user_role_id') setIsDriver(value === '2');
      return updated;
    });
  };

  // const handleInvite = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const partnerCompanyId = localStorage.getItem('companyId') || 1;
  //     const userToken = localStorage.getItem('userToken');

  //     // Capturar a assinatura se for um motorista
  //     let signatureBlob = null;
  //     if (isDriver && signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
  //       const dataURL = signaturePadRef.current.toDataURL('image/png');
  //       const byteString = atob(dataURL.split(',')[1]);
  //       const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  //       const arrayBuffer = new ArrayBuffer(byteString.length);
  //       const uint8Array = new Uint8Array(arrayBuffer);
  //       for (let i = 0; i < byteString.length; i++) {
  //         uint8Array[i] = byteString.charCodeAt(i);
  //       }
  //       signatureBlob = new Blob([uint8Array], { type: mimeString });
  //     }

  //     // Criar um objeto FormData para enviar os dados
  //     const formDataToSend = new FormData();
  //     formDataToSend.append('email', formData.email);
  //     formDataToSend.append('user_role_id', formData.user_role_id);
  //     formDataToSend.append('first_name', formData.first_name);
  //     formDataToSend.append('last_name', formData.last_name);
  //     formDataToSend.append('phone', formData.phone);
  //     formDataToSend.append('username', formData.username);
  //     formDataToSend.append('password', formData.password);
  //     formDataToSend.append('c_password', formData.confirmPassword);

  //     // Se for motorista, adicionar campos específicos
  //     if (isDriver) {
  //       formDataToSend.append('partner_company_id', partnerCompanyId);
  //       formDataToSend.append('trailer_type', formData.trailer_type);
  //       if (signatureBlob) {
  //         formDataToSend.append('signature', signatureBlob, 'signature.png');
  //       }
  //     }

  //     const endpoint = isDriver
  //       ? `${process.env.REACT_APP_API_PREFIX}/user-driver`
  //       : `${process.env.REACT_APP_API_PREFIX}/users`;

  //     const headers = {
  //       "Screen-Name": "/User",
  //       "Authorization": `Bearer ${userToken}`,
  //     };


  //     console.log('Form Data:', {
  //       email: formData.email,
  //       user_role_id: formData.user_role_id,
  //       first_name: formData.first_name,
  //       last_name: formData.last_name,
  //       phone: formData.phone,
  //       username: formData.username,
  //       password: formData.password,
  //       c_password: formData.confirmPassword,
  //       ...(isDriver && {
  //         partner_company_id: partnerCompanyId,
  //         trailer_type: formData.trailer_type,
  //         signature: signatureBlob ? 'Signature file attached' : 'No signature',
  //       }),
  //     });

  //     // Enviar os dados para o endpoint correto
  //     const response = await axios.post(endpoint, formDataToSend, { headers });

  //     console.log("Response from server:", response.data);

  //     // Exemplo de requisição com fetch
  //     fetch(endpoint, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         ...headers,
  //       },
  //       body: JSON.stringify(formDataToSend),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error);
  //       });

  //     // Exibir alerta de sucesso com temporizador de 5 segundos e redirecionar para o dashboard
  //     Swal.fire({
  //       title: 'Success!',
  //       text: 'Registration successful! You will be redirected shortly.',
  //       icon: 'success',
  //       timer: 5000, // Temporizador de 5 segundos
  //       timerProgressBar: true,
  //       showConfirmButton: false // Remove o botão de confirmação
  //     }).then(() => {
  //       window.location.href = '/Dashboard'; // Redirecionar para o dashboard
  //     });

  //   } catch (err) {
  //     const errorMessage = err.response?.data?.message || 'An unknown error occurred.';
  //     Swal.fire({
  //       title: 'Error!',
  //       text: errorMessage,
  //       icon: 'error',
  //       confirmButtonText: 'OK',
  //     });
  //   }
  // };


  const handleInvite = async (event) => {
    event.preventDefault();
    try {
      const partnerCompanyId = localStorage.getItem('companyId') || 1;
      const userToken = localStorage.getItem('userToken');

      // Capturar a assinatura se for um motorista
      let signatureBlob = null;
      if (isDriver && signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
        const dataURL = signaturePadRef.current.toDataURL('image/png');
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        signatureBlob = new Blob([uint8Array], { type: mimeString });
      }

      // Criar um objeto FormData para enviar os dados
      const formDataToSend = new FormData();
      formDataToSend.append('email', formData.email);
      formDataToSend.append('user_role_id', formData.user_role_id);
      formDataToSend.append('first_name', formData.first_name);
      formDataToSend.append('last_name', formData.last_name);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('username', formData.username);
      formDataToSend.append('password', formData.password);
      formDataToSend.append('c_password', formData.confirmPassword);

      // Se for motorista, adicionar campos específicos
      if (isDriver) {
        formDataToSend.append('partner_company_id', partnerCompanyId);
        formDataToSend.append('trailer_type', formData.trailer_type);
        if (signatureBlob) {
          formDataToSend.append('signature', signatureBlob, 'signature.png');
        }
      }

      const endpoint = isDriver
        ? `${process.env.REACT_APP_API_PREFIX}/user-driver`
        : `${process.env.REACT_APP_API_PREFIX}/users`;

      const headers = {
        "Screen-Name": "/User",
        "Authorization": `Bearer ${userToken}`,
      };

      // Enviar os dados para o endpoint correto
      const response = await axios.post(endpoint, formDataToSend, { headers });

      console.log("Response from server:", response.data);

      // Exibir alerta de sucesso
      Swal.fire({
        title: 'Success!',
        text: 'Registration successful!',
        icon: 'success',
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Limpar os campos do formulário após o sucesso
      setFormData({
        email: '',
        user_role_id: '2', // Valor padrão para o select
        first_name: '',
        last_name: '',
        phone: '',
        username: '',
        password: '',
        confirmPassword: '',
        trailer_type: '', // Se necessário
      });

      // Limpar a assinatura, se aplicável
      if (isDriver && signaturePadRef.current) {
        signaturePadRef.current.clear();
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'An unknown error occurred.';
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };




  const handleCreateInvite = async (event) => {
    event.preventDefault();
    try {
      const userToken = localStorage.getItem('userToken');
      const payload = {
        user_role_id: role,
        email,
      };

      await axios.post(`${process.env.REACT_APP_API_PREFIX}/partnercompany/invite_staff`, payload, {
        headers: {
          'Screen-Name': '/PartnerCompany',
          Authorization: `Bearer ${userToken}`,
        },
      });

      Swal.fire({
        title: 'Success!',
        text: 'Employee created successfully!',
        icon: 'success',
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Limpar os campos do formulário após sucesso
      setEmail('');
      setRole('2'); // Valor padrão para o select (Driver)
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unknown error occurred.';
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };


  // Função para buscar o nome do papel (role)
  const getUserRoleName = (roleId) => {
    const roles = [
      { id: 1, user_role_name: "Admin" },
      { id: 2, user_role_name: "Driver" },
      { id: 3, user_role_name: "Customer" },
      { id: 4, user_role_name: "Carrier Company Owner" },
      { id: 5, user_role_name: "Broker Company Owner" },
      { id: 6, user_role_name: "Shipper Company Owner" },
      { id: 7, user_role_name: "Dispatcher" },
      { id: 8, user_role_name: "Dealer" },
      { id: 9, user_role_name: "Accountant" },
    ];

    const role = roles.find((r) => r.id === roleId);
    return role ? role.user_role_name : "Unknown";
  };





  const getUserDetailsFromToken = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      console.error('Token não encontrado.');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/users`, {
        headers: {
          'Screen-Name': '/Profile',
          'Authorization': `Bearer ${token}`, // Substituído userToken por token
        },
      });
      setUsers(response.data.data || []);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error.message);
      if (error.response?.status === 401) {
        Swal.fire('Unauthorized', 'Sua sessão expirou. Faça login novamente.', 'error');
        // Redirecionar para a página de login, se necessário.
      }
    }
  };

  useEffect(() => {
  }, [users]);

  useEffect(() => {
    getUserDetailsFromToken();
  }, []);


  useEffect(() => {
    if (isDriver && canvasRef.current && !signaturePadRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [isDriver]);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleViewDetails = (user) => {
    setSelectedUser(user); // Define o usuário selecionado
    setIsModalOpen(true);  // Abre o modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Fecha o modal
    setSelectedUser(null); // Reseta o usuário selecionado
  };

  const handleSaveChanges = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save these changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const userToken = localStorage.getItem('userToken');
          const headers = {
            'Screen-Name': '/User',
            'Authorization': `Bearer ${userToken}`,
          };

          await axios.post(
            `${process.env.REACT_APP_API_PREFIX}/users/${selectedUser.id}`,
            selectedUser,
            { headers }
          );

          // Exibir mensagem de sucesso com temporizador
          Swal.fire({
            title: 'Saved!',
            html: `<p>The changes for <strong>${selectedUser.first_name} ${selectedUser.last_name}</strong> have been saved successfully.</p>`,
            icon: 'success',
            timer: 5000, // 5 segundos
            timerProgressBar: true,
            showConfirmButton: false,
          });

          // Atualize a lista de usuários após salvar
          getUserDetailsFromToken();
          setIsModalOpen(false);
        } catch (error) {
          const errorMessage = error.response?.data?.message || 'An error occurred.';
          Swal.fire('Error!', errorMessage, 'error');
        }
      }
    });
  };


  const handleResetPassword = async (email) => {
    try {
      const userToken = localStorage.getItem("userToken");

      const headers = {
        "Screen-Name": "/User",
        Authorization: `Bearer ${userToken}`,
      };

      // Faz a requisição para o endpoint de resetar senha
      await axios.post(
        `${process.env.REACT_APP_API_PREFIX}/password/email`,
        { email },
        { headers }
      );

      // Mensagem de sucesso com o e-mail
      Swal.fire({
        title: "Success!",
        text: `Password reset link has been sent to the email: ${email}`,
        icon: "success",
        timer: 4000, // Adiciona um temporizador de 4 segundos
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      // Mensagem de erro
      const errorMessage = error.response?.data?.message || "Failed to send reset email.";
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };



  const handleToggle = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      const userToken = localStorage.getItem("userToken");

      const headers = {
        "Screen-Name": "/User",
        Authorization: `Bearer ${userToken}`,
      };

      // Faz a requisição para o backend
      await axios.post(
        `${process.env.REACT_APP_API_PREFIX}/user-is-active/${userId}`,
        { is_active: newStatus },
        { headers }
      );

      // Atualiza o estado local
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, is_active: newStatus } : user
        )
      );

      Swal.fire({
        title: "Success!",
        text: `User status has been updated to ${newStatus === 1 ? "active" : "inactive"}.`,
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update user status.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };





  if (loading) return <MainSpinner />;

  return (

    <div className="px-4">
      {loading ? (
        <MainSpinner />
      ) : (
        <div>
          <Card className="mt-4 mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
            <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card2')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                {/* <path d="M12 8V12L15.276 15.276M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="orange" stroke-width="2" stroke-linejoin="round" /> */}
              </svg>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Invite Employee</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`transition-transform duration-300 ${isOpen.card2 ? 'rotate-180' : 'rotate-0'}`}
              >
                <path d="M18 15l-6-6-6 6" />
              </svg>
            </div>
            {isOpen.card2 && (
              <form onSubmit={handleCreateInvite} className="space-y-6">
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label htmlFor="Email" className="block text-sm font-medium text-gray-600">Email</label>
                    <input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder='email@cajuu.com'
                    />
                  </div>


                  <div className="flex-1">
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role:</label>
                    <select
                      id="role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="1">Admin</option>
                      <option value="2" selected="selected">Driver</option>
                      <option value="7">Dispatcher</option>
                      <option value="9">Accountant</option>
                    </select>
                  </div>
                </div>

                <div class="flex justify-center mt-6">
                  <button
                    className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
                    type="submit"
                    style={{
                      backgroundColor: '#FF8C00',
                      color: 'white',
                      /* padding: '12px', */
                      borderRadius: '6px',
                      fontWeight: 'bold',
                      /* width: '100%', */
                      marginTop: '16px',
                      transition: 'background-color 0.3s ease',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#FFA500'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF8C00'}
                  >
                    Invite
                  </button>
                </div>
              </form>
            )}
          </Card>



          <Card className="mt-4 mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
            <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card1')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"></svg>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Create Employee</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`transition-transform duration-300 ${isOpen.card1 ? 'rotate-180' : 'rotate-0'}`}
              >
                <path d="M18 15l-6-6-6 6" />
              </svg>
            </div>
            {isOpen.card1 && (
              <form onSubmit={handleInvite} className="space-y-6 p-6">
                {/* Seleção de papel (Role) */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="user_role_id" className="block text-sm font-medium text-gray-600">Role</label>
                    <select
                      id="user_role_id"
                      name="user_role_id"
                      value={formData.user_role_id}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="1">Admin</option>
                      <option value="7">Dispatcher</option>
                      <option value="9">Accountant</option>
                      <option value="2">Driver</option>
                    </select>
                  </div>
                </div>



                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-600">First Name</label>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      value={formData.first_name}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter first name"
                    />
                  </div>
                  <div className="flex-1">
                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-600">Last Name</label>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      value={formData.last_name}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter last name"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="flex-1">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="email@example.com"
                    />
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-600">Phone</label>
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="(123) 456-7890"
                    />
                  </div>

                  <div className="flex-1">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-600">Username</label>
                    <input
                      id="username"
                      name="username"
                      type="text"
                      value={formData.username}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter username"
                    />
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter password"
                    />
                  </div>

                  <div className="flex-1">
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">Confirm Password</label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Confirm password"
                    />
                  </div>
                </div>

                {/* Campos para motorista (visíveis apenas se o papel "Driver" for selecionado) */}
                {isDriver && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="trailer_type" className="block text-sm font-medium text-gray-600">Trailer Type</label>
                      <select
                        id="trailer_type"
                        name="trailer_type"
                        value={formData.trailer_type}
                        onChange={handleChange}
                        className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Trailer Type</option>
                        <option value="open">Open</option>
                        <option value="enclosed">Enclosed</option>
                      </select>
                    </div>

                    <div className="col-span-2 mt-4">
                      <label className="block text-sm font-medium text-gray-600">Signature</label>
                      <canvas
                        ref={canvasRef}
                        width={400}
                        height={150}
                        className="border border-gray-300 rounded-md"
                      ></canvas>
                      <button
                        type="button"
                        onClick={() => signaturePadRef.current.clear()}
                        className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md"
                      >
                        Clear Signature
                      </button>
                    </div>
                  </div>
                )}

                {/* Botão de registro */}
                <div className="flex justify-center mt-6">
                  <button
                    type="submit"
                    className="bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-600 transition-colors"
                  >
                    Register
                  </button>
                </div>
              </form>
            )}
          </Card>



          <Card className="mt-4 mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
            <div className="p-6">
              <table className="w-full">
                <thead className="bg-white">
                  <tr>
                    <th className="px-4 py-2 border-b border-gray-300 text-orange-500 text-lg">Name</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-orange-500 text-lg hidden sm:table-cell">Email</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-orange-500 text-lg hidden md:table-cell">Role</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-orange-500 text-lg hidden md:table-cell">Phone</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-orange-500 text-lg">Active</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-orange-500 text-lg"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr key={user.id} className="text-gray-700 dark:text-gray-400">
                      <td className="px-4 py-2 align-middle">
                        <div className="flex items-center space-x-4">
                          <img
                            src={`${process.env.REACT_APP_API_FILE_PREFIX}/${user.profile_picture_path}` || fallbackImage}
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = fallbackImage;
                            }}
                            className="rounded-full w-10 h-10"
                          />
                          <span className="text-lg">{user.first_name} {user.last_name}</span>
                        </div>
                      </td>
                      <td className="px-4 py-2 text-center align-middle hidden sm:table-cell">
                        <span className="text-lg">{user.email}</span>
                      </td>
                      <td className="px-4 py-2 text-center align-middle hidden md:table-cell">
                        <span className="text-lg">{getUserRoleName(user.user_role_id)}</span>
                      </td>
                      <td className="px-4 py-2 text-center align-middle hidden md:table-cell">
                        <span className="text-lg">{user.phone}</span>
                      </td>
                      <td className="px-4 py-2 text-center align-middle">
                        <Switch
                          checked={user.is_active === 1}
                          onChange={() => handleToggle(user.id, user.is_active)}
                          color="warning"
                          sx={{
                            '& .Mui-checked': { color: '#FFA500' },
                            '& .Mui-checked + .MuiSwitch-track': { backgroundColor: '#FFA500' },
                          }}
                        />
                      </td>
                      <td className="px-4 py-2 text-center">
                        <button
                          onClick={() => handleViewDetails(user)}
                          className="bg-orange-500 text-white px-3 py-1 rounded hover:bg-orange-600 text-sm"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {isModalOpen && selectedUser && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>

                  <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md sm:max-w-lg md:max-w-2xl lg:max-w-3xl mx-4" >

                    <h2 className="text-xl font-bold text-center mb-6 text-gray-700">
                      User Details
                    </h2>

                    <form>

                      <div className="mb-4">
                        <label className="block text-sm font-medium mb-2">Role</label>
                        <select
                          value={selectedUser.user_role_id}
                          onChange={(e) =>
                            setSelectedUser({ ...selectedUser, user_role_id: e.target.value })
                          }
                          className="border px-3 py-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                        >
                          {[
                            { id: 1, user_role_name: "Admin" },
                            { id: 2, user_role_name: "Driver" },
                            { id: 3, user_role_name: "Customer" },
                            { id: 4, user_role_name: "Carrier Company Owner" },
                            { id: 5, user_role_name: "Broker Company Owner" },
                            { id: 6, user_role_name: "Shipper Company Owner" },
                            { id: 7, user_role_name: "Dispatcher" },
                            { id: 8, user_role_name: "Dealer" },
                            { id: 9, user_role_name: "Accountant" },
                          ].map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.user_role_name}
                            </option>
                          ))}
                        </select>
                      </div>


                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full">
                          <label className="block text-sm font-medium mb-2">First Name</label>
                          <input
                            type="text"
                            value={selectedUser.first_name}
                            onChange={(e) =>
                              setSelectedUser({ ...selectedUser, first_name: e.target.value })
                            }
                            className="border px-3 py-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                          />
                        </div>
                        <div className="w-full">
                          <label className="block text-sm font-medium mb-2">Last Name</label>
                          <input
                            type="text"
                            value={selectedUser.last_name}
                            onChange={(e) =>
                              setSelectedUser({ ...selectedUser, last_name: e.target.value })
                            }
                            className="border px-3 py-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                          />
                        </div>
                      </div>


                      <div className="mb-4">
                        <label className="block text-sm font-medium mb-2">Email</label>
                        <input
                          type="email"
                          value={selectedUser.email}
                          onChange={(e) =>
                            setSelectedUser({ ...selectedUser, email: e.target.value })
                          }
                          className="border px-3 py-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                      </div>


                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full">
                          <label className="block text-sm font-medium mb-2">Phone</label>
                          <input
                            type="text"
                            value={selectedUser.phone}
                            onChange={(e) =>
                              setSelectedUser({ ...selectedUser, phone: e.target.value })
                            }
                            className="border px-3 py-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                          />
                        </div>
                        <div className="w-full">
                          <label className="block text-sm font-medium mb-2">Username</label>
                          <input
                            type="text"
                            value={selectedUser.username}
                            onChange={(e) =>
                              setSelectedUser({ ...selectedUser, username: e.target.value })
                            }
                            className="border px-3 py-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                          />
                        </div>
                      </div>


                      <div className="flex flex-wrap justify-between gap-4 mt-6">
                        <button
                          type="button"
                          onClick={handleSaveChanges}
                          className="bg-orange-500 text-white font-bold px-6 py-2 rounded hover:bg-orange-600 transition-colors flex-1"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => handleResetPassword(selectedUser.email)}
                          className="bg-red-500 text-white font-bold px-6 py-2 rounded hover:bg-red-600 transition-colors flex-1"
                        >
                          Reset Password
                        </button>
                        <button
                          type="button"
                          onClick={handleCloseModal}
                          className="bg-gray-400 text-white font-bold px-6 py-2 rounded hover:bg-gray-600 transition-colors flex-1"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}


              {/* Pagination Controls */}
              <div className="flex flex-wrap justify-center mt-4 space-x-2 sm:space-x-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-2 bg-white text-gray-600 rounded disabled:opacity-50 text-sm sm:text-base"
                >
                  <GrLinkPrevious />
                </button>
                <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4">
                  {Array.from({ length: totalPages }, (_, index) => {
                    // Mostrar no máximo 4 botões no mobile, mas todos em telas maiores
                    const shouldDisplay =
                      totalPages <= 4 || window.innerWidth >= 640 || // Exibir todos se totalPages <= 4 ou em telas médias
                      (index >= currentPage - 2 && index <= currentPage + 1); // Exibir botões em torno do botão atual

                    return shouldDisplay ? (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-2 rounded text-sm sm:text-base ${currentPage === index + 1
                            ? "bg-orange-500 text-white"
                            : "bg-gray-200 text-gray-600"
                          }`}
                      >
                        {index + 1}
                      </button>
                    ) : null;
                  })}
                </div>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-3 py-2 bg-white text-gray-600 rounded disabled:opacity-50 text-sm sm:text-base"
                >
                  <GrLinkNext />
                </button>
              </div>
            </div>
          </Card>
        </div >
      )}
    </div >
  );
};

export default StaffManagement;
